import { absolutePath } from "./absolutePath";

export const apiAnalytics = {
  get: (startDateTime: string, endDateTime: string) =>
    absolutePath("/admin/api_analytics/usage_counts", {
      start_date_time: startDateTime,
      end_date_time: endDateTime,
    }),
  getEndpointMetrics: (
    startDateTime: string,
    endDateTime: string,
    apiType: string,
  ) =>
    absolutePath("/admin/api_analytics/endpoint_metrics", {
      start_date_time: startDateTime,
      end_date_time: endDateTime,
      api_type: apiType,
    }),
};
